import { useEffect, useRef, useState } from 'react';
import './Keywords.css';

function Keywords(props: { word: string, removeWord: boolean, removedWord: () => any, typeWord: boolean, typedWord: () => any }) {
  const prevProps = useRef(props);
  const [keyword, setKeyword] = useState(prevProps.current.word);

  function simulateBackspaces(): void {
    if (keyword.length > 0) {
      const sliced = keyword.slice(0, keyword.length - 1);
      setKeyword(sliced);
    } else {
      props.removedWord();
    }
  }

  function simulateTyping(word: string): void {
    if (keyword !== word) {
      const substringFound = word.includes(keyword);
      if (!substringFound) {
        setKeyword(word[0]);
      } else {
        const substring = word.slice(0, keyword.length + 1);
        setKeyword(substring);
      }
    } else {
      props.typedWord();
    }
  }

  useEffect(() => {
    if (props.word !== prevProps.current.word) {
      prevProps.current = props;
    }
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (props.typeWord) {
        simulateTyping(props.word);
      } else {
        clearInterval(interval);
      }
    }, 50);

    return () => clearInterval(interval);
  }, [props.typeWord, props.word, keyword]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (props.removeWord) {
        simulateBackspaces();
      } else {
        clearInterval(interval);
      }
    }, 50);

    return () => clearInterval(interval);
  }, [props.word, props.removeWord, keyword])

  return <span>{keyword}<span className='cursor'>_</span></span>;
};

export default Keywords;
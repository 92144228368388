import { useEffect } from 'react';
import './Content.css';

function Content(props: { content: string }) {
  const content = document.querySelector('.content');

  function fadeIn(): void {
    content?.classList.add('content--fade-in');
    const timer = setTimeout(() => {
      content?.classList.remove('content--fade-in');
      clearTimeout(timer);
    }, 500);
  }

  function fadeOut(): void {
    content?.classList.add('content--fade-out');
    const timer = setTimeout(() => {
      content?.classList.remove('content--fade-out');
      clearTimeout(timer);
    }, 500);
  }

  useEffect(() => {
    if (props.content !== '') {
      fadeIn();
    } else {
      fadeOut();
    }
  }, [props.content])

  return (
    <div className='content'>
      <p className='text--backdrop'>
        { props.content }        
      </p>
    </div>
  )
}

export default Content;

import { useEffect, useState } from 'react';
import './App.css';
import Content from './components/Content';
import Footer from './components/Footer';
import Header from './components/Header';
import { TechTypeEnum } from './enums/techTypeEnum';
import './shared/fonts/FredokaOne-Regular.ttf';
import './shared/styles/fonts.css';
import './shared/styles/typography.css';

function App() {
  const [type, setType] = useState(TechTypeEnum.DEFAULT);
  const [fullScreen, setFullScreen] = useState(false);
  const [animating, setAnimating] = useState(false);
  const [content, setContent] = useState('');
  const [age, setAge] = useState(0);

  const header = document.querySelector('.header__wrapper');
  const dev = document.querySelector('.background__division--dev');
  const photos = document.querySelector('.background__division--photos');
  const about = document.querySelector('.background__division--about');
  const divisions = [
    dev, photos, about
  ];

  window.addEventListener('popstate', (event) => {
    const hash = (event?.target as Window).location?.hash;
    if (hash === '') {
      setType(TechTypeEnum.DEFAULT);
      onBackClicked();
    }
  });

  function onBackClicked() {
    setContent('');
    for (let div of divisions) {
      div?.classList.remove('background__division--active');
      div?.classList.remove('background__division--hide');
    }

    blinkHeader();
    header?.classList.remove('header__wrapper--top');
    setFullScreen(false);
    setType(TechTypeEnum.DEFAULT);
  }

  function onCtaClicked() {
    switch (type) {
      case TechTypeEnum.DEVELOPMENT:
        setContent('SSBhbSBzdGlsbCB3b3JraW5nIG9uIHdoYXQgdG8gc2hvdyBoZXJlLi4u');
        dev?.classList.remove('background__division--hide');
        photos?.classList.add('background__division--hide');
        about?.classList.add('background__division--hide');
        window.location.hash = '#development';
        break;
      case TechTypeEnum.PHOTOGRAPHY:
        setContent('Expect a collection of my favourite photographs here anytime soon!');
        photos?.classList.remove('background__division--hide');
        dev?.classList.add('background__division--hide');
        about?.classList.add('background__division--hide');
        window.location.hash = '#photography';
        break;
      case TechTypeEnum.ABOUT:
        setContent(`Hi! I am Frank and I am ${age} years old. I am a front end developer specialised in Angular. I am currently employed at de Volksbank through Pancompany.`);
        about?.classList.remove('background__division--hide');
        photos?.classList.add('background__division--hide');
        dev?.classList.add('background__division--hide');
        window.location.hash = '#about';
        break;
      default:
        setContent('');
        window.location.hash = '';
        break;
    }

    setFullScreen(true);
    blinkHeader();
    header?.classList.add('header__wrapper--top');
  }

  function blinkHeader() {
    setAnimating(true);
    header?.classList.add('header__wrapper--blink');
    const timer = setTimeout(() => {
      header?.classList.remove('header__wrapper--blink');
      setAnimating(false);
      clearTimeout(timer);
    }, 500);
  }

  useEffect(() => {
    switch (type) {
      case TechTypeEnum.DEVELOPMENT:
        dev?.classList.add('background__division--active');
        photos?.classList.remove('background__division--active');
        about?.classList.remove('background__division--active');
        break;
      case TechTypeEnum.PHOTOGRAPHY:
        photos?.classList.add('background__division--active');
        dev?.classList.remove('background__division--active');
        about?.classList.remove('background__division--active');
        break;
      case TechTypeEnum.ABOUT:
        about?.classList.add('background__division--active');
        photos?.classList.remove('background__division--active');
        dev?.classList.remove('background__division--active');
        break;
      default:
        break;
    }
  }, [type, dev?.classList, photos?.classList, about?.classList])

  function getAge(dateString: string): number {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  useEffect(() => {
    setAge(getAge('1996-08-28'));
  }, []);

  return (
    <div>
      <div className='background__division--wrapper'>
        <Header type={type} fullScreen={fullScreen} animating={animating} clickedCta={() => { onCtaClicked() }} clickedBack={() => { onBackClicked() }}></Header>
        <Content content={content}></Content>
        <Footer></Footer>
        <div className='background__division background__division--dev' onClick={() => setType(TechTypeEnum.DEVELOPMENT)}></div>
        <div className='background__division background__division--photos' onClick={() => setType(TechTypeEnum.PHOTOGRAPHY)}></div>
        <div className='background__division background__division--about' onClick={() => setType(TechTypeEnum.ABOUT)}></div>
      </div>
    </div>
  );
}

export default App;

import './Footer.css';

function Footer() {
  return (
    <div className="footer__wrapper">
      <div className="footer__social footer__social--github"><a href="https://github.com/fseverijnen" target="_blank" rel="noopener noreferrer"/></div>
      <div className="footer__social footer__social--linkedin"><a href="https://linkedin.com/in/frank-severijnen" target="_blank" rel="noopener noreferrer"/></div>
    </div>
  )
}

export default Footer;
import { useEffect, useState } from 'react';
import { TechTypeEnum } from '../../enums/techTypeEnum';
import Keywords from '../Keywords';
import './Header.css';

function Header(props: { clickedCta: () => any, clickedBack: () => any, type?: TechTypeEnum, fullScreen?: boolean, animating?: boolean }) {
  const keywords = [
    '.tech',
    'frontend',
    'photography',
    'me'
  ];
  const [removeWord, doRemoveWord] = useState(false);
  const [typeWord, doTypeWord] = useState(false);
  const [word, setWord] = useState(keywords[0]);
  const [cta, setCta] = useState('');
  const [showBackButton, doShowBackButton] = useState(false);

  function onRemovedWord(): void {
    doRemoveWord(false);

    switch (props.type) {
      case TechTypeEnum.DEVELOPMENT:
        setWord(keywords[1]);
        break;
      case TechTypeEnum.PHOTOGRAPHY:
        setWord(keywords[2]);
        break;
      case TechTypeEnum.ABOUT:
        setWord(keywords[3]);
        break;
      default:
        setWord(keywords[0]);
        break;
    }

    doTypeWord(true);
  }

  function onTypedWord(): void {
    if (props.type !== TechTypeEnum.DEFAULT) {
      setCta(getCta())
    } else {
      setCta('');
    }

    doTypeWord(false);
  }

  function getCta(): string {
    switch (props.type) {
      case TechTypeEnum.DEVELOPMENT:
        return 'Decode my work'
      case TechTypeEnum.ABOUT:
        return 'Get to know me';
      case TechTypeEnum.PHOTOGRAPHY:
        return 'View my pictures';
      default:
        return `Discover my ${word}!`;
    }
  }

  function onGoBack(): void {
    setCta('');
    doRemoveWord(true);
    props.clickedBack();
  }

  function onCta(): void {
    setCta('');
    props.clickedCta();
  }

  useEffect(() => {
    setCta('');
    doRemoveWord(true);
  }, [props.type])

  useEffect(() => {
    if (props.fullScreen && !props.animating) {
      doShowBackButton(true);
    } else {
      doShowBackButton(false);
    }
  }, [props.animating, props.fullScreen])

  return (
    <div className='header__wrapper'>
      <h1 className='header noselect'>
        <span className='italic'>
          creative
        </span>
        <span className='lightest'>
          with
        </span>
        <Keywords word={word} removeWord={removeWord} removedWord={() => { onRemovedWord() }} typeWord={typeWord} typedWord={() => { onTypedWord() }}></Keywords>
      </h1>
      {
        showBackButton ?
          <div className='header__back text--backdrop' onClick={() => { onGoBack() }}>
            {'< Home'}
          </div> : <div></div>
      }
      {
        !props.fullScreen && cta ?
          <div className='header__cta text--backdrop' onClick={() => { onCta() }}>
            {cta}
          </div>
          : <div></div>
      }
    </div>
  )
}

export default Header;